<template>
  <ValidationProvider
    :rules="validationRules"
    class="question column"
    :class="width"
    tag="div"
    v-slot="{ errors, dirty, valid }"
    v-if="!reviewMode"
  >
    <div class="question-text">
      <p class="question-text__code" v-if="showQuestionCode">
        {{ question.code }}
      </p>
      <p class="question-text__question" v-html="decodeHtml(question.question[lang])"></p>
      <p class="help is-danger" v-if="errors[0]">{{ errors[0] }}</p>
      <p class="help" v-else>{{ questionHelp }}</p>
    </div>
    <div class="question-content card">
      <div class="card-content">
        <b-field
          :type="{ 'is-danger': errors[0], 'is-success': dirty && valid }"
        >
          <vue-dropzone
            :value="value[question.qid]"
            :destroyDropzone="true"
            :id="`downloadFileDropzone`"
            :options="dropzoneOptions"
            :useCustomSlot="true"
            @dropzone-error="onError"
            @dropzone-change="onChange"
            ref="file_upload"
          >
          </vue-dropzone>
        </b-field>
      </div>
    </div>
  </ValidationProvider>
  <div class="question" v-else>
    <div class="is-flex is-align-items-center">
      <div class="question-text has-text-weight-bold column p-0">
        <p class="question-text__code" v-if="showQuestionCode">
          {{ question.code }}
        </p>
        <p class="question-text__question" v-html="decodeHtml(question.question[lang])"></p>
      </div>
      <b-button type="is-text" @click="$emit('edit')">
        <b-icon icon="pencil" type="is-primary"></b-icon>
      </b-button>
    </div>
    <div class="question-content">
      <b-input
        expanded
        :value="uploadedFiles"
        :readonly="loading"
      ></b-input>
    </div>
  </div>
</template>
<script>
import { QuestionMixin } from "@/mixins/question";
import Dropzone from "./shared/Dropzone.vue";
export default {
  name: "FileUpload",
  mixins: [QuestionMixin],
  components: {
    VueDropzone: Dropzone,
  },
  methods: {
    onError(file, message) {
      console.log(file, message);
    },
    onChange(data) {
      this.$emit("input", { [this.question.qid]: data });
    },
  },
  computed: {
    dropzoneOptions() {
      return {
        params: {
          survey: this.$route.params?.code,
          questionCode: this.question?.code,
          rid: this.respondent?._id
        },
        maxFiles: this.qOpts.max,
        acceptedFiles: this.qOpts.ext,
        maxFilesize: this.qOpts.size / 1024,
      };
    },
    uploadedFiles() {
      let files = []
      this.value[`${this.question.qid}`]?.forEach(url => {
        files.push(url.split('/').pop())
      })
      return files.join(', ')
    }
  },
};
</script>

<style lang="scss">
.vue-dropzone > .dz-preview .dz-remove {
  top: 5px;
  right: 5px;
  bottom: inherit;
}
</style>
