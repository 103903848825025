<template>
  <vue-dropzone
    ref="dropzone"
    v-bind="$attrs"
    :destroyDropzone="true"
    :useCustomSlot="true"
    @vdropzone-success="vdropzoneSuccess"
    @vdropzone-error="vdropzoneError"
    @vdropzone-removed-file="onRemove"
    :options="dropzoneOptions"
  >
    <slot name="custom-content">
      <div class="dropzone-custom-content">
        <h3
          class="dropzone-custom-title has-text-primary is-size-5 is-size-3-desktop"
        >
          {{ $t("dropzone.title") }}
        </h3>
        <div class="has-text-weight-bold is-size-7 is-size-6-desktop">
          {{ $t("dropzone.subtitle") }}
        </div>
      </div>
    </slot>
  </vue-dropzone>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import isEmpty from "lodash/isEmpty";

export default {
  name: "Dropzone",
  props: {
    value: {},
    options: {
      type: Object,
      default: () => ({}),
    },
    destroyDropzone: {
      type: Boolean,
      default: true,
    },
    useCustomSlot: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      uploaded: [],
      defaultOptions: {
        url: `${process.env.VUE_APP_ADMIN_API}/api/respondent/upload`,
        params: { type: "test" },
        thumbnailWidth: 200,
        addRemoveLinks: true,
        filesizeBase: 1024,
        maxFilesize: 1,
        acceptedFiles: "image/*,video/*,applications/*,.csv,.xlsx",
        dictFileTooBig: this.$t("dropzone.fileTooBig"),
        dictInvalidFileType: this.$t("dropzone.invalidFileType"),
        dictResponseError: this.$t("dropzone.responseError", {
          statusCode: "{statusCode}",
        }),
        dictCancelUpload: this.$t("dropzone.cancelUpload"),
        dictCancelUploadConfirmation: this.$t(
          "dropzone.cancelUploadConfirmation"
        ),
        dictRemoveFile: this.$t("dropzone.removeFile"),
        dictMaxFilesExceeded: this.$t("dropzone.maxFilesExceeded"),
      },
    };
  },
  methods: {
    vdropzoneSuccess(file, response) {
      this.uploaded.push({
        id: file.upload.uuid,
        url: response.data.url,
        name: file.name,
        size: file.size,
        type: file.type,
      });
      this.$emit(
        "dropzone-change",
        this.uploaded.map((file) => file.url)
      );
    },
    vdropzoneError(file, message, xhr) {
      this.$emit("dropzone-error", file, message, xhr);
    },
    async onRemove(file) {
      await this.uploaded.splice(
        this.uploaded.findIndex((el) => el.id === file.upload.uuid),
        1
      );
      this.$emit(
        "dropzone-change",
        this.uploaded.map((file) => file.url)
      );
    }
  },
  computed: {
    dropzoneOptions() {
      return {
        ...this.defaultOptions,
        ...this.options,
      };
    },
  },
	watch: {
    value () {
      const uploaded = JSON.parse(sessionStorage.getItem('uploaded'))
      sessionStorage.setItem('uploaded', JSON.stringify([ ...uploaded, ...this.uploaded]))
    }
	},
  mounted () {
    if (!isEmpty(this.value)) {
      const uploaded = JSON.parse(sessionStorage.getItem('uploaded'))
      uploaded?.forEach((file) => {
        if (this.value.includes(file.url)) this.$refs.dropzone.manuallyAddFile(file, file.url)
      });
    }
  }
};
</script>

<style lang="scss">
.vue-dropzone {
  border: 3px dashed #00bd9d;
}
</style>
